import React from 'react';
import { ThemeProvider, Box } from '@parsleyhealth/cilantro-ui';
import styled from '@emotion/styled';
import { Layout } from '../../components/Layout';
import { DigiohScript } from '../../components/ThirdParty/digioh-script';

const Container = styled(({ marginTop, ...rest }) => <Box {...rest} />)<{
  marginTop: boolean;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? '70px' : 0)};
  background: white;
`;

const HiltonLayout = ({ children, paddingTop = true, marginTop = true }) => {
  return (
    <ThemeProvider>
      <DigiohScript />
      <Layout navbarType="HILTON" showNavbar bypassRedesign showFooter={false}>
        <Container pt={paddingTop ? 'm' : 'none'} marginTop={marginTop}>
          {children}
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default HiltonLayout;
