import { useStaticQuery, graphql } from 'gatsby';
import { SanityHiltonConfig, SanityHiltonSidebarCard } from 'graphql-types';

interface TResponse extends SanityHiltonConfig {
  SidebarWhatIsPH: SanityHiltonSidebarCard;
  Sidebar150Off: SanityHiltonSidebarCard;
  SidebarDisclaimer: SanityHiltonSidebarCard;
  SidebarFranchiseDisclaimer: SanityHiltonSidebarCard;
  sidebars: any;
}

export const useHiltonEducationConfig = (): TResponse => {
  const { hiltonEducationConfig, sidebars } = useStaticQuery(
    graphql`
      fragment MetaImage on SanityImage {
        hotspot {
          y
          x
          width
          height
        }
        crop {
          bottom
          left
          right
          top
        }
        asset {
          url
        }
      }
      fragment MetaDataHilton on SanityMetaDataHilton {
        metaTitle
        metaDescription
        metaImage {
          ...MetaImage
        }
        noindex
      }
      fragment MetaDataHiltonGuide on SanityMetaDataHiltonGuide {
        metaTitle
        metaDescription
        metaImage {
          ...MetaImage
        }
        noindex
      }
      query getHiltonEducationConfig {
        hiltonEducationConfig: sanityHiltonConfig {
          hiltonFreeConsult {
            ...SanityTextBlockSectionFragment
          }
          metaDataHilton {
            ...MetaDataHilton
          }
          metaDataHiltonGuide {
            ...MetaDataHiltonGuide
          }
          sidebarTitle
          sidebarCard {
            heading
            body: _rawBody
            image {
              _type
              ...SanityImageFragment
            }
            cta {
              ...SanityButtonsFragment
            }
          }
          hiltonPageHeader {
            headline
            subHeadline
          }
          mainFeaturedVideo {
            ...HiltonVideoCardInfo
          }
        }
        sidebars: allSanityHiltonSidebarCard {
          nodes {
            _id
            heading
            body: _rawBody
            image {
              _type
              ...SanityImageFragment
            }
            cta {
              ...SanityButtonsFragment
            }
          }
        }
      }
    `
  );

  const getSideBar = (id: string) => {
    return sidebars.nodes.find((s: SanityHiltonSidebarCard) => s._id === id);
  };

  const SidebarWhatIsPH = getSideBar('953b9633-59ad-4962-9e13-ee9ec3598ae8');
  const Sidebar150Off = getSideBar('e05500d7-5ff0-479b-bdc8-79d741a8c846');
  const SidebarDisclaimer = getSideBar('398456fe-42d3-48b9-ab5b-38b433a5c159');
  const SidebarFranchiseDisclaimer = getSideBar(
    '74c3e557-0421-4ebe-82a0-665974ca24a7'
  );

  return {
    ...hiltonEducationConfig,
    sidebars,
    SidebarWhatIsPH,
    Sidebar150Off,
    SidebarDisclaimer,
    SidebarFranchiseDisclaimer
  };
};
