import { useStaticQuery, graphql } from 'gatsby';
import { SanityHiltonPost } from 'graphql-types';

interface FeaturedPosts {
  featuredPosts: SanityHiltonPost[];
}

const useFeaturedHiltonPosts = (): FeaturedPosts => {
  const data = useStaticQuery(
    graphql`
      query getFeatureHiltonPosts {
        sanityHiltonConfig {
          featuredPosts {
            _id
            id
            slug {
              current
            }
            title
            author {
              ...HiltonPostAuthor
            }
          }
        }
      }
    `
  );

  return {
    featuredPosts:
      data?.sanityHiltonConfig?.featuredPosts.sort(() => Math.random() - 0.5) ??
      []
  };
};

export default useFeaturedHiltonPosts;
