import { useStaticQuery, graphql } from 'gatsby';
import { SanityNavigationItem } from 'graphql-types';

interface Categories {
  categories: SanityNavigationItem[];
}

const useHiltonEducationCategories = (): Categories => {
  const { categories } = useStaticQuery(
    graphql`
      query getHiltonEducationCategories {
        categories: sanityNavigation(
          sanityTitle: { eq: "hilton-education-theme-navigation" }
        ) {
          navigationItem {
            title
            path
            id: _key
          }
        }
      }
    `
  );

  return {
    categories: categories?.navigationItem || []
  };
};

export default useHiltonEducationCategories;
