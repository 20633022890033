import React from 'react';
import { Box } from '@parsleyhealth/cilantro-ui';
import { SanityBasicImage, SanityBlock, SanityHiltonPost } from 'graphql-types';
import { WebsiteWrapper } from '@parsleyhealth/cilantro-ui';
import BlogSimpleHero from '../Blog/blog-simple-hero';
import BlogGrid from '../Blog/blog-grid';
import BlogSectionTitle from '../Blog/blog-section-title';
import BlogSidebarCard from '../Blog/blog-sidebar-card';
import BlogFeaturedPosts from '../Blog/BlogFeaturedPosts';
import BlockContent from '../BlockContent';
import HiltonEducationCategories from './HiltonEducationCategories';
import { useHiltonEducationConfig } from '../../hooks/use-hilton-education-config';
import HiltonLayout from './hilton-layout';
import { HiltonEducationBanner } from './hilton-education-banner';
import HiltonPostGallery from './hilton-post-gallery';
import useFeaturedHiltonPosts from '../../hooks/use-featured-hilton-posts';

interface HiltonEducationPageGenericTemplateProps {
  description?: SanityBlock[];
  image?: SanityBasicImage;
  popularPosts?: SanityHiltonPost[];
  posts: SanityHiltonPost[];
  title: string;
}

export const HiltonEducationPageGenericTemplate = ({
  description,
  image,
  popularPosts,
  posts,
  title
}: HiltonEducationPageGenericTemplateProps) => {
  const {
    SidebarWhatIsPH,
    Sidebar150Off,
    SidebarDisclaimer,
    SidebarFranchiseDisclaimer
  } = useHiltonEducationConfig();
  const { featuredPosts } = useFeaturedHiltonPosts();

  const hasPopularPosts = popularPosts && popularPosts.length;

  return (
    <HiltonLayout paddingTop={false}>
      <BlogSimpleHero
        title={title}
        description={
          description ? <BlockContent blocks={description} /> : undefined
        }
        image={image}
      />
      <Box>
        <WebsiteWrapper>
          <BlogGrid>
            <Box pb="m">
              <BlogSectionTitle mb="l">Latest from {title}</BlogSectionTitle>
              <HiltonPostGallery posts={posts} />
            </Box>
            <Box pl={['none', 'none', 'none', 'l']} className="gap-[20px]">
              {Sidebar150Off && (
                <>
                  <BlogSidebarCard card={Sidebar150Off} prefix="siderail-1" />
                </>
              )}
              <BlogFeaturedPosts
                posts={hasPopularPosts ? popularPosts : featuredPosts}
                title={hasPopularPosts ? 'Popular Posts' : 'Featured Posts'}
              />
              <br />
              <HiltonEducationCategories />
              <br />
              {SidebarWhatIsPH && (
                <>
                  <BlogSidebarCard card={SidebarWhatIsPH} prefix="siderail-2" />
                </>
              )}
              {SidebarDisclaimer && (
                <>
                  <br />
                  <BlogSidebarCard
                    card={SidebarDisclaimer}
                    prefix="siderail-2"
                  />
                </>
              )}
              {SidebarFranchiseDisclaimer && (
                <>
                  <br />
                  <BlogSidebarCard
                    card={SidebarFranchiseDisclaimer}
                    prefix="siderail-2"
                  />
                </>
              )}
            </Box>
          </BlogGrid>
        </WebsiteWrapper>
      </Box>
      <HiltonEducationBanner />
    </HiltonLayout>
  );
};
