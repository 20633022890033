import React from 'react';
import TextBlockSectionWrapper from '../../text-block-section-wrapper';
import './hilton-education-banner.css';
import { useHiltonEducationConfig } from '../../../hooks/use-hilton-education-config';

export const HiltonEducationBanner = () => {
  const { hiltonFreeConsult } = useHiltonEducationConfig();

  return (
    <TextBlockSectionWrapper {...hiltonFreeConsult} className="blog-banner" />
  );
};
