import React from 'react';
import { graphql } from 'gatsby';
import { SanityBlogPost, SanityHiltonCategory } from 'graphql-types';
import { default as SEO } from '../../components/SEO';
import { HiltonEducationPageGenericTemplate } from '../../components/HiltonEducation/hilton-education-page-generic-template';

interface HiltonCategoryTemplateProps {
  data: {
    category: SanityHiltonCategory;
    posts: {
      edges: {
        node: SanityBlogPost;
      }[];
    };
  };
}

const HiltonEducationCategoryTemplate = ({
  data
}: HiltonCategoryTemplateProps) => {
  const { category } = data;
  const { metaTitle, metaDescription, metaImage, noindex } = category;

  if (!category) {
    return null;
  }

  const posts = data.posts.edges.map(({ node }) => node).slice(0, 10) || [];

  const popularPosts =
    category?.popularHiltonPosts.sort(() => Math.random() - 0.5) ?? undefined;

  let image = null;
  if (metaImage) {
    image = metaImage;
  } else if (category.backgroundImage) {
    image = category.backgroundImage;
  }

  return (
    <>
      <SEO
        pageTitle={metaTitle ? metaTitle : category.title}
        pageDescription={
          metaDescription
            ? metaDescription
            : category?.description?.[0]?.children[0].text
        }
        pageImage={image}
        noindex={noindex}
      />
      <HiltonEducationPageGenericTemplate
        title={category.title}
        description={category.description}
        image={category.backgroundImage}
        popularPosts={popularPosts}
        posts={posts}
      />
    </>
  );
};

export const query = graphql`
  query HiltonCategoryQuery($sanityID: String) {
    category: sanityHiltonCategory(_id: { eq: $sanityID }) {
      title
      metaTitle
      metaDescription
      metaImage {
        ...MetaImage
      }
      noindex
      description: _rawDescription
      backgroundImage {
        _type
        ...SanityImageFragment
      }
      popularHiltonPosts {
        ...HiltonRelatedPost
      }
    }
    posts: allSanityHiltonPost(
      filter: {
        themes: { elemMatch: { _id: { eq: $sanityID } } }
        slug: { current: { ne: null } }
      }
      sort: { order: DESC, fields: publishedDate }
    ) {
      edges {
        node {
          ...HiltonPostCardInfo
        }
      }
    }
  }
`;

export default HiltonEducationCategoryTemplate;
